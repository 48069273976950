<template>
  <div class="tutorials-view mt-2">
    <base-alert2
      v-if="alert.open"
      class="alert"
      :type="alert.type"
      :message="alert.message"
      @close="alert.open = false"
    />
    <dialog-confirmation
      description="Você está prestes a excluir um tutorial. Tem certeza que deseja realizar esta ação?"
      :is-open="openDialog"
      @confirmed="handleRemoveTutorialStep2"
      @close="openDialog = false"
    />
    <modal-create
      :is-open="openModal"
      @close="openModal = false"
      @create="handleCreateTutorialStep2"
      @update="handleUpdateTutorialStep2"
    />
    <base-button @click="handleCreateTutorialStep1">
      <i
        class="fas fa-plus fa-md"
        @click="$emit('remove-survey', row.item._id)"
      />
      Adicionar Tutorial
    </base-button>
    <tutorials-table
      :tutorials="tutorials"
      :tutorials-meta="tutorialsMeta"
      @change-tutorials="handleChangeTutorials"
      @update-tutorial="handleUpdateTutorialStep1"
      @remove-tutorial="handleRemoveTutorialStep1"
    />
  </div>
</template>

<script>
import DialogConfirmation from '@/components/DialogConfirmation.vue'

import ModalCreate from '@/components/Tutorials/Modal'
import TutorialsTable from '@/components/Tutorials/Table'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TutorialsAdmin',
  data(){
    return {
      openDialog: false,
      openModal: false,
      alert: {
        open: false,
        message: '',
        type: 'success'
      },
      messages: {
        'Error creating new tutorial': 'Erro ao criar novo tutorial',
        'Tutorial created successfully': 'Tutorial criado com sucesso',
        'Error updating new tutorial': 'Erro ao atualziar novo tutorial',
        'Tutorial updated successfully': 'Tutorial atualizado com sucesso',
        'Error removing new tutorial': 'Erro ao remover novo tutorial',
        'Tutorial removed successfully': 'Tutorial removido com sucesso',
      }
    }
  },
  components: {
    TutorialsTable,
    ModalCreate,
    DialogConfirmation
  },
  computed: {
    ...mapGetters('tutorials', {
      tutorial: 'getTutorial',
      tutorials: 'getTutorials',
      tutorialsMeta: 'getTutorialsMeta',
    }),
  },
  methods: {
    ...mapActions('tutorials', {
      handleGetTutorial: 'handleGetTutorial',
      handleSetTutorial: 'handleSetTutorial',
      handleGetTutorials: 'handleGetTutorials',
      handleCreateTutorial: 'handleCreateTutorial',
      handleRemoveTutorial: 'handleRemoveTutorial',
      handleUpdateTutorial: 'handleUpdateTutorial',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    async handleChangeTutorials(payload){
      const params = {
        ...this.tutorialsMeta,
        ...payload
      }

      await this.handleGetTutorials(params);
    },
    handleResetAlert(){
      this.alert = {
        open: false,
        message: '',
        type: 'success'
      }
    },
    async handleResponseAPI(response){
      if(response.errors){
        this.alert.message = this.messages[response.errors]
        this.alert.type = 'danger'
        this.alert.open = true
        return
      } else if(response.message){
        this.alert.message = this.messages[response.message]
        this.alert.type = 'success'
        this.alert.open = true
      }

      await this.handleGetTutorials({
        sortIn: 'desc'
      })
    },
    async handleCreateTutorialStep1(){
      this.handleResetAlert()

      this.openModal = true
    },
    async handleCreateTutorialStep2(){
      const response = await this.handleCreateTutorial();

      this.handleResponseAPI(response)
      this.openModal = false
    },
    async handleRemoveTutorialStep1(_id){
      this.handleResetAlert()

      this.openDialog = true

      this.handleSetTutorial({ _id })
    },
    async handleRemoveTutorialStep2(){
      const response = await this.handleRemoveTutorial()

      this.handleResponseAPI(response)
      this.openDialog = false
    },
    async handleUpdateTutorialStep1(_id){
      this.handleResetAlert()

      const response = await this.handleGetTutorial(_id)

      if(!response.errors) this.openModal = true

      this.handleResponseAPI(response)
    },
    async handleUpdateTutorialStep2(){
      const response = await this.handleUpdateTutorial()

      this.handleResponseAPI(response)
      this.openModal = false
    }
  },
  async created(){
    await this.handleGetTutorials({
      sortIn: 'desc'
    });
    const breadcrumb = {
      page: 'Tutoriais',
      subPage: {
        name: 'Tutoriais',
        active: true
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
  },
}
</script>

<style lang="scss" scoped>
.tutorials-view {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
