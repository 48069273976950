<template>
  <div class="tutorials-table">
    <base-table
      v-if="tutorials && tutorialsMeta"
      :columns="columns"
      :data="tutorials"
      :total-items="tutorialsMeta.total"
      :start-items="tutorialsMeta.skip + 1"
      :max-items="tutorialsMeta.limit"
      @set-page="handleChangeTutorials"
    >
      <template v-slot:body-link="row">
        <a :href="row.item.link" target="_blank">{{row.item.link}}</a>
      </template>
      <template v-slot:body-date="row">
        {{ row.item.createdAt ? formatDate(row.item.createdAt) : '' }}
      </template>
      <template v-slot:body-actions="row">
        <i
          class="fas fa-pen fa-lg"
          @click="$emit('update-tutorial', row.item._id)"
        />
        <i
          class="fas fa-trash fa-lg"
          @click="$emit('remove-tutorial', row.item._id)"
        />
      </template>
    </base-table>
  </div>
</template>

<script>
import format from 'date-fns/esm/format/index'

export default {
  name: 'tutorialsTable',
  props: {
    tutorials: { type: Array, require: true },
    tutorialsMeta: { type: Object, require: true }
  },
  data(){
    return {
      columns: [
        { key: 'link', value: 'Link' },
        { key: 'date', value: 'Criado em' },
        { key: 'actions', value: 'Ações' },
      ],
    }
  },
  methods: {
    formatDate(date){
      return format(new Date(date), 'dd/MM/yyyy')
    },
    handleChangeTutorials(page){
      const params = {
        skip: ((page - 1) * this.tutorialsMeta.limit)
      }
      this.$emit('change-tutorials', params)
    }
  },
}
</script>

<style lang="scss" scoped>
.tutorials-table {
  display: flex;
  flex-direction: column;
  gap: 16px;
  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
